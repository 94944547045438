import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Box, 
  Container, 
  Paper, 
  Typography, 
  Button, 
} from '@mui/material';
import Header from '../components/Header';

const farmTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#DAA520' },       // Goldenrod
    secondary: { main: '#8B4513' },     // SaddleBrown
    error: { main: '#d32f2f' },
    background: { default: '#F5DEB3', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#555555' },
  },
});

const Cancel = () => {
  return (
    <ThemeProvider theme={farmTheme}>
      <CssBaseline />
      <Box sx={{ 
        minHeight: '100vh', 
        backgroundColor: farmTheme.palette.background.default,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <Container maxWidth="sm" sx={{ 
          display: 'flex', 
          flexGrow: 1, 
          alignItems: 'center', 
          justifyContent: 'center',
          py: 4 
        }}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              textAlign: 'center', 
              borderRadius: 2,
              backgroundColor: 'white'
            }}
          >
            <Typography 
              variant="h4" 
              sx={{ 
                mb: 3, 
                color: farmTheme.palette.primary.main,
                fontWeight: 'bold'
              }}
            >
              Payment Cancelled
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 3, 
                color: farmTheme.palette.text.secondary 
              }}
            >
              It looks like your payment was cancelled or did not complete. 
              This can happen for various reasons:
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                mb: 3, 
                color: farmTheme.palette.text.secondary,
                fontStyle: 'italic'
              }}
            >
              • You may have closed the payment window
              • There was an issue with your payment method
              • You chose to cancel the transaction
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 3, 
                color: farmTheme.palette.text.secondary 
              }}
            >
              Please try again or contact our support if you need assistance.
            </Typography>
            <Button
              component={RouterLink}
              to="/"
              variant="contained"
              color="primary"
              sx={{ 
                py: 1.5, 
                px: 4, 
                fontWeight: 'bold',
                textTransform: 'none'
              }}
            >
              Return to Home
            </Button>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Cancel;