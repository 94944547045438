// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CartDropdown from './CartDropdown';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Badge,
  Box,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const Header = () => {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [cartQuantity, setCartQuantity] = useState(0);
  const navigate = useNavigate();

  // Check if the user is an admin.
  const isAdmin =
    user &&
    (user.email === 'donatelloc@slc.za.com' ||
      user.email === 'quentinv@slc.za.com' ||
      user.email === 'sales@ndizachem.co.za'|| 
      user.email === 'novussec@gmail.com');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  // Listen for cart updates. (We now assume the cart stores qtyA and qtyB.)
  useEffect(() => {
    function syncCartQuantity() {
      const cartData = JSON.parse(localStorage.getItem('cart') || '{}');
      const qtyA = cartData.qtyA || 0;
      const qtyB = cartData.qtyB || 0;
      setCartQuantity(qtyA + qtyB);
    }
    syncCartQuantity();
    window.addEventListener('cartUpdated', syncCartQuantity);
    return () => {
      window.removeEventListener('cartUpdated', syncCartQuantity);
    };
  }, []);

  const toggleCart = () => setIsCartOpen((prev) => !prev);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      toast.info('You have signed out.');
      navigate('/login');
    } catch (error) {
      toast.error('Error signing out: ' + error.message);
    }
  };

  return (
    <AppBar
      position="sticky"
      color="default"
      sx={{ borderBottom: '1px solid', borderColor: 'grey.300', boxShadow: 1 }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          component={Link}
          to="/home"
          variant="h4"
          sx={{ textDecoration: 'none', color: 'primary.main', fontWeight: 'bold' }}
        >
          Ndizachem
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, position: 'relative' }}>
          {isAdmin && (
            <Button component={Link} to="/admin" sx={{ color: 'text.primary' }}>
              Admin
            </Button>
          )}
          {user ? (
            <Button onClick={handleSignOut} sx={{ color: 'text.primary' }}>
              Sign Out
            </Button>
          ) : (
            <>
              <Button component={Link} to="/login" sx={{ color: 'text.primary' }}>
                Login
              </Button>
              <Button component={Link} to="/signup" sx={{ color: 'text.primary' }}>
                Signup
              </Button>
            </>
          )}
          <Box sx={{ position: 'relative' }}>
            <IconButton onClick={toggleCart} sx={{ color: 'text.primary' }}>
              <Badge badgeContent={cartQuantity} color="error">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            {isCartOpen && <CartDropdown onClose={toggleCart} />}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
