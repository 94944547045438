import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Box, 
  Container, 
  Paper, 
  Typography, 
  Button, 
  Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const farmTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#DAA520' },
    secondary: { main: '#8B4513' },
    error: { main: '#d32f2f' },
    background: { default: '#F5DEB3', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#555555' },
  },
});

const Cart = () => {
  const [cart, setCart] = useState(null);
  const navigate = useNavigate();

  // Load cart on component mount
  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      try {
        setCart(JSON.parse(storedCart));
      } catch (error) {
        toast.error('Error loading cart');
        localStorage.removeItem('cart');
      }
    }
  }, []);

  // Handle cart removal
  const clearCart = () => {
    localStorage.removeItem('cart');
    setCart(null);
    toast.info('Cart has been cleared');
  };

  // Handle checkout
  const handleCheckout = () => {
    if (!cart || cart.quantity <= 0) {
      toast.error('Your cart is empty');
      return;
    }
    navigate('/checkout');
  };

  // If cart is empty
  if (!cart || cart.quantity <= 0) {
    return (
      <ThemeProvider theme={farmTheme}>
        <CssBaseline />
        <Box sx={{ 
          minHeight: '100vh', 
          backgroundColor: farmTheme.palette.background.default,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Header />
          <Container maxWidth="sm" sx={{ 
            display: 'flex', 
            flexGrow: 1, 
            alignItems: 'center', 
            justifyContent: 'center',
            py: 4 
          }}>
            <Paper 
              elevation={3} 
              sx={{ 
                p: 4, 
                textAlign: 'center', 
                borderRadius: 2,
                backgroundColor: 'white'
              }}
            >
              <Typography 
                variant="h4" 
                sx={{ 
                  mb: 3, 
                  color: farmTheme.palette.primary.main,
                  fontWeight: 'bold'
                }}
              >
                Your Cart is Empty
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  mb: 3, 
                  color: farmTheme.palette.text.secondary 
                }}
              >
                Looks like you haven't added any hay bales to your cart yet.
              </Typography>
              <Button
                component={RouterLink}
                to="/"
                variant="contained"
                color="primary"
                sx={{ 
                  py: 1.5, 
                  px: 4, 
                  fontWeight: 'bold',
                  textTransform: 'none'
                }}
              >
                Continue Shopping
              </Button>
            </Paper>
          </Container>
          <ToastContainer />
        </Box>
      </ThemeProvider>
    );
  }

  // Cart with items
  const total = (cart.quantity * cart.balePrice).toFixed(2);

  return (
    <ThemeProvider theme={farmTheme}>
      <CssBaseline />
      <Box sx={{ 
        minHeight: '100vh', 
        backgroundColor: farmTheme.palette.background.default,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <Header />
        <Container maxWidth="md" sx={{ 
          flexGrow: 1, 
          py: 4 
        }}>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              borderRadius: 2,
              backgroundColor: 'white'
            }}
          >
            <Typography 
              variant="h4" 
              sx={{ 
                mb: 4, 
                color: farmTheme.palette.primary.main,
                fontWeight: 'bold',
                textAlign: 'center'
              }}
            >
              Your Cart
            </Typography>

            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={8}>
                <Typography variant="h6">Bale of Hay</Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: 'center' }}>
                <Typography variant="h6">Quantity</Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: 'right' }}>
                <Typography variant="h6">Subtotal</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mb: 3, alignItems: 'center' }}>
              <Grid item xs={8}>
                <Typography variant="body1">
                  Premium Hay Bale at R{cart.balePrice.toFixed(2)} each
                </Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: 'center' }}>
                <Typography variant="h6">{cart.quantity}</Typography>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: 'right' }}>
                <Typography variant="h6">R{total}</Typography>
              </Grid>
            </Grid>

            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mt: 4 
            }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={clearCart}
                startIcon={<DeleteIcon />}
              >
                Clear Cart
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCheckout}
                sx={{ 
                  py: 1.5, 
                  px: 4, 
                  fontWeight: 'bold',
                  textTransform: 'none'
                }}
              >
                Proceed to Checkout
              </Button>
            </Box>
          </Paper>
        </Container>
        <ToastContainer />
      </Box>
    </ThemeProvider>
  );
};

export default Cart;