// src/App.js (example)
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Checkout from './pages/Checkout';
import Cart from './pages/Cart';
import Success from './pages/Success';
import Cancel from './pages/Cancel'
import AdminDashboard from './pages/AdminDashboard';
import ProtectedRoute from './components/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        
        {/* Public routes for auth */}
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />

        {/* Cart & checkout can be protected if you want users to sign in first */}
        <Route path="/cart" element={<ProtectedRoute />}>
          <Route index element={<Cart />} />
        </Route>
        <Route path="/checkout" element={<ProtectedRoute />}>
          <Route index element={<Checkout />} />
        </Route>
        <Route path="/success" element={<ProtectedRoute />}>
          <Route index element={<Success />} />
        </Route>
        <Route path="/cancel" element={<ProtectedRoute />}>
          <Route index element={<Cancel />} />
        </Route>

        {/* Admin route - also behind ProtectedRoute */}
        <Route path="/admin" element={<ProtectedRoute />}>
          <Route index element={<AdminDashboard />} />
        </Route>
        
      </Routes>

     {/* SINGLE ToastContainer at the end, centered & narrower */}
     <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        draggable
        pauseOnHover
        stacked
        limit={3}
      />
    </Router>
  );
}

export default App;
