// src/components/CartDropdown.js
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Paper,
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Grid
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import VerifiedIcon from '@mui/icons-material/Verified';
import haybaleImg from '../images/haybale.jpg';
import farmImg from '../images/farm.jpg';
import { supabase } from '../supabase';

const CartDropdown = ({ onClose }) => {
  // Read stored quantities from localStorage.
  const cartFromStorage = JSON.parse(localStorage.getItem("cart") || '{}');
  const initialQtyA = cartFromStorage.qtyA || 0;
  const initialQtyB = cartFromStorage.qtyB || 0;
  const [qtyA, setQtyA] = useState(initialQtyA);
  const [qtyB, setQtyB] = useState(initialQtyB);

  // Live prices from the database.
  const [livePriceA, setLivePriceA] = useState(null);
  const [livePriceB, setLivePriceB] = useState(null);
  // Available stock for each product.
  const [stockA, setStockA] = useState(0);
  const [stockB, setStockB] = useState(0);

  // Fetch live prices and available stock for Grade A and Grade B from Supabase.
  useEffect(() => {
    async function fetchLiveData() {
      try {
        const { data, error } = await supabase
          .from('wheatStock')
          .select('*')
          .in('id', [1, 2]);
        if (error) throw error;
        const productA = data.find(item => item.id === 1);
        const productB = data.find(item => item.id === 2);
        setLivePriceA(productA?.price || 110);
        setLivePriceB(productB?.price || 95);
        setStockA(productA?.available || 0);
        setStockB(productB?.available || 0);
      } catch (err) {
        console.error("Error fetching live data:", err.message);
        // Fall back to defaults if needed.
        setLivePriceA(110);
        setLivePriceB(95);
        setStockA(0);
        setStockB(0);
      }
    }
    fetchLiveData();
  }, []);

  // Use live prices (or defaults) for calculations.
  const unitPriceA = livePriceA !== null ? livePriceA : 110;
  const unitPriceB = livePriceB !== null ? livePriceB : 95;
  const totalA = (qtyA * unitPriceA).toFixed(2);
  const totalB = (qtyB * unitPriceB).toFixed(2);
  const grandTotal = (parseFloat(totalA) + parseFloat(totalB)).toFixed(2);

  const dropdownRef = useRef(null);

  // When updating, store both quantities and the live prices.
  const updateCart = (newQtyA, newQtyB) => {
    setQtyA(newQtyA);
    setQtyB(newQtyB);
    localStorage.setItem("cart", JSON.stringify({ qtyA: newQtyA, qtyB: newQtyB, priceA: unitPriceA, priceB: unitPriceB }));
    window.dispatchEvent(new Event("cartUpdated"));
  };

  const incrementA = () => updateCart(qtyA + 1, qtyB);
  const decrementA = () => {
    if (qtyA > 1) {
      updateCart(qtyA - 1, qtyB);
    } else {
      updateCart(0, qtyB);
    }
  };

  const incrementB = () => updateCart(qtyA, qtyB + 1);
  const decrementB = () => {
    if (qtyB > 1) {
      updateCart(qtyA, qtyB - 1);
    } else {
      updateCart(qtyA, 0);
    }
  };

  // Remove an entire product from the cart.
  const removeItem = (item) => {
    if (item === 'A') {
      updateCart(0, qtyB);
    } else if (item === 'B') {
      updateCart(qtyA, 0);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <Paper 
      ref={dropdownRef}
      sx={{
        position: 'absolute', 
        top: '100%', 
        right: 0, 
        width: 350, 
        p: 2, 
        zIndex: 1000,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: '#f9f9f9'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
          Your Hay Cart
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'green' }}>
          <VerifiedIcon fontSize="small" sx={{ mr: 0.5 }} />
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            Field Fresh
          </Typography>
        </Box>
      </Box>

      {(qtyA > 0 || qtyB > 0) ? (
        <>
          {qtyA > 0 && (
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <img 
                  src={haybaleImg} 
                  alt="Grade A Bale" 
                  style={{ width: '100%', borderRadius: 8, boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }} 
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Grade A Bale
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Premium quality hay bale.
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 2 }}>
                    R{unitPriceA.toFixed(2)} each
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton 
                      size="small" 
                      onClick={decrementA} 
                      sx={{ border: '1px solid #ddd', mr: 1 }}
                    >
                      <RemoveIcon fontSize="small" />
                    </IconButton>
                    <Typography sx={{ mx: 1 }}>{qtyA}</Typography>
                    <IconButton 
                      size="small" 
                      onClick={incrementA} 
                      disabled={qtyA >= stockA} // Disable plus if quantity equals available stock
                      sx={{ border: '1px solid #ddd', ml: 1 }}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Subtotal: R{totalA}
                  </Typography>
                  <IconButton onClick={() => removeItem('A')} color="error" size="small">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          )}
          {qtyB > 0 && (
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <img 
                  src={farmImg} 
                  alt="Grade B Bale" 
                  style={{ width: '100%', borderRadius: 8, boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }} 
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Grade B Bale
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Standard quality hay bale.
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 2 }}>
                    R{unitPriceB.toFixed(2)} each
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton 
                      size="small" 
                      onClick={decrementB} 
                      sx={{ border: '1px solid #ddd', mr: 1 }}
                    >
                      <RemoveIcon fontSize="small" />
                    </IconButton>
                    <Typography sx={{ mx: 1 }}>{qtyB}</Typography>
                    <IconButton 
                      size="small" 
                      onClick={incrementB} 
                      disabled={qtyB >= stockB} // Disable plus if quantity equals available stock
                      sx={{ border: '1px solid #ddd', ml: 1 }}
                    >
                      <AddIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                    Subtotal: R{totalB}
                  </Typography>
                  <IconButton onClick={() => removeItem('B')} color="error" size="small">
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          )}
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LocalOfferIcon sx={{ mr: 1, color: 'success.main' }} />
              <Typography variant="body2">
                Grand Total
              </Typography>
            </Box>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              R{grandTotal}
            </Typography>
          </Box>
          <Button 
            component={Link} 
            to="/checkout" 
            variant="contained" 
            fullWidth 
            size="large"
            sx={{ borderRadius: 2, textTransform: 'none', fontWeight: 'bold', py: 1.5 }}
            onClick={onClose}
          >
            Proceed to Checkout
          </Button>
        </>
      ) : (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Your cart feels a bit light today
          </Typography>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Browse Hay Bales
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default CartDropdown;
