// src/components/ProductCard.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import { toast } from 'react-toastify';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  IconButton,
  createTheme
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ImageCarousel from './ImageCarousel'; // Ensure this component exists

// Define an inline theme to use for styling colors.
const farmTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#DAA520' },
    secondary: { main: '#8B4513' },
    background: { default: '#F5DEB3', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#555555' },
    error: { main: '#d32f2f' },
  },
});

const ProductCard = ({ product }) => {
  const [quantity, setQuantity] = useState(1);
  const [available, setAvailable] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchStock();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStock = async () => {
    try {
      const { data, error } = await supabase
        .from(product.stockKey)
        .select('*')
        .eq('id', product.id)
        .single();
      if (error) throw error;
      if (data) setAvailable(data.available);
    } catch (err) {
      setMessage(`Error fetching stock: ${err.message}`);
    }
  };

  const incrementQuantity = () => {
    if (available !== null && quantity < available) {
      setQuantity(quantity + 1);
    } else {
      toast.warning("Reached maximum available stock.");
    }
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCart = () => {
    if (available === null) {
      toast.error("Stock data not loaded yet. Please try again.");
      return;
    }
    if (available === 0) {
      toast.error("Sorry, this item is currently out of stock.");
      return;
    }
    const cart = JSON.parse(localStorage.getItem("cart") || '{}');
    // Store separate quantities for Grade A (id 1) and Grade B (id 2)
    let newCart = { ...cart };
    if (product.id === 1) {
      newCart.qtyA = (newCart.qtyA || 0) + quantity;
      if (newCart.qtyA > available) {
        newCart.qtyA = available;
        toast.warning(`Adjusted quantity to available stock: ${available}`);
      }
    } else if (product.id === 2) {
      newCart.qtyB = (newCart.qtyB || 0) + quantity;
      if (newCart.qtyB > available) {
        newCart.qtyB = available;
        toast.warning(`Adjusted quantity to available stock: ${available}`);
      }
    }
    localStorage.setItem("cart", JSON.stringify(newCart));
    toast.success(`Added ${product.name} to your cart!`);
    window.dispatchEvent(new Event("cartUpdated"));
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', boxShadow: 4 }}>
      <ImageCarousel images={product.images} />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography
          variant="h4"
          sx={{ mb: 2, color: farmTheme.palette.primary.main, fontWeight: 'bold' }}
        >
          {product.name}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          {product.description}
        </Typography>
        <Typography
          variant="h5"
          sx={{ mb: 2, color: farmTheme.palette.primary.main }}
        >
          Price: R{product.price.toFixed(2)}
        </Typography>
        {available !== null && (
          <Typography
            variant="body2"
            sx={{
              mb: 2,
              color:
                available === 0
                  ? farmTheme.palette.error.main
                  : farmTheme.palette.primary.main,
            }}
          >
            {available === 0
              ? "Currently out of stock"
              : `In Stock: ${available}`}
          </Typography>
        )}
        {message && (
          <Typography
            variant="body2"
            sx={{ mb: 2, color: farmTheme.palette.error.main }}
          >
            {message}
          </Typography>
        )}
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <IconButton onClick={decrementQuantity} disabled={quantity <= 1}>
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography variant="h6">{quantity}</Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={incrementQuantity}
              disabled={available !== null && quantity >= available}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleAddToCart}
          disabled={available === 0}
        >
          {available === 0 ? "Out of Stock" : "Add to Cart"}
        </Button>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
