// src/pages/Home.js
import React, { useEffect, useState } from 'react';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ProductCard from '../components/ProductCard';
import { supabase } from '../supabase';
import farmImg from '../images/farm.jpg';
import haybaleImg from '../images/haybale.jpg';

const farmTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#DAA520' },
    secondary: { main: '#8B4513' },
    background: { default: '#F5DEB3', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#555555' },
  },
});

const Home = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      // Fetch products (wheatStock records for Grade A Bale and Grade B Bale)
      const { data, error } = await supabase
        .from('wheatStock')
        .select('*')
        .in('id', [1, 2]);
      if (error) {
        console.error("Error fetching products", error);
      } else {
        // Map each database row to your product object.
        const mappedProducts = data.map(row => ({
          id: row.id,
          name: row.id === 1 ? "Grade A Bale" : "Grade B Bale",
          price: row.price, // Use the price from the database
          description: row.id === 1
            ? "Premium quality Grade A Bale."
            : "Standard quality Grade B Bale.",
          stockKey: 'wheatStock',
          images: row.id === 1 ? [haybaleImg, farmImg] : [farmImg, haybaleImg],
        }));
        // Force Grade A Bale to load first.
        const gradeABale = mappedProducts.find(item => item.id === 1);
        const otherProducts = mappedProducts.filter(item => item.id !== 1);
        setProducts([gradeABale, ...otherProducts]);
      }
    }
    fetchProducts();
  }, []);

  return (
    <ThemeProvider theme={farmTheme}>
      <CssBaseline />
      <Header />
      <Box>
        {/* Hero Section */}
        <Box
          sx={{
            backgroundImage: `url(${farmImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            py: 10,
            textAlign: 'center',
            color: '#fff'
          }}
        >
          <Typography variant="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
            Welcome to Ndizachem
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Experience premium farm products crafted with excellence.
          </Typography>
        </Box>

        {/* Shop Section */}
        <Container sx={{ py: 5 }}>
          <Typography
            variant="h3"
            align="center"
            sx={{ mb: 4, color: farmTheme.palette.primary.main, fontWeight: 'bold' }}
          >
            Our Products
          </Typography>
          <Grid container spacing={4}>
            {products.map(product => (
              <Grid item key={product.id} xs={12} md={6}>
                <ProductCard product={product} />
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* About & Contact Accordion Section */}
        <Container sx={{ py: 5 }}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                About & Contact Us
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" sx={{ mb: 2 }}>
                At Ndizachem, we deliver premium quality farm products designed to support your agricultural needs. Our commitment to excellence means every product meets the highest standards.
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Address:</strong> 1 Bunsen Street, Sasolburg, 1947
              </Typography>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Phone:</strong> +27 123 456 789
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> sales@ndizachem.co.za
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Container>
      </Box>
      <Footer />
    </ThemeProvider>
  );
};

export default Home;
