// src/pages/Checkout.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { supabase } from '../supabase';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const farmTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#DAA520' },      // Goldenrod
    secondary: { main: '#8B4513' },    // SaddleBrown
    error: { main: '#d32f2f' },
    background: { default: '#F5DEB3', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#555555' },
  },
});

const Checkout = () => {
  const [processing, setProcessing] = useState(false);
  const [qtyA, setQtyA] = useState(0);
  const [qtyB, setQtyB] = useState(0);
  const [priceA, setPriceA] = useState(0);
  const [priceB, setPriceB] = useState(0);
  const [total, setTotal] = useState(0);
  const [stockA, setStockA] = useState(null);
  const [stockB, setStockB] = useState(null);
  
  // Customer details
  const [customerEmail, setCustomerEmail] = useState('guest@example.com');
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [customerCity, setCustomerCity] = useState('');
  const [customerPostalCode, setCustomerPostalCode] = useState('');

  // Read cart data from localStorage on mount
  useEffect(() => {
    const cartData = JSON.parse(localStorage.getItem("cart") || '{}');
    if(cartData.qtyA !== undefined) setQtyA(Number(cartData.qtyA));
    if(cartData.qtyB !== undefined) setQtyB(Number(cartData.qtyB));
  }, []);

  // Auto-populate email from Firebase Auth if available.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email) {
        setCustomerEmail(user.email);
      }
    });
    return unsubscribe;
  }, []);

  // Fetch stock and prices from database
  useEffect(() => {
    fetchStockAndPrices();
  }, []);

  // Recalculate total whenever quantities or prices change
  useEffect(() => {
    setTotal(qtyA * priceA + qtyB * priceB);
  }, [qtyA, qtyB, priceA, priceB]);

  const fetchStockAndPrices = async () => {
    try {
      const { data, error } = await supabase
        .from('wheatStock')
        .select('*')
        .in('id', [1, 2]);
      if (error) throw error;
      data.forEach(item => {
        if (item.id === 1) {
          setStockA(item.available);
          setPriceA(item.price);
        }
        if (item.id === 2) {
          setStockB(item.available);
          setPriceB(item.price);
        }
      });
    } catch (err) {
      toast.error("Error fetching stock and prices: " + err.message);
    }
  };

  const incrementQtyA = () => {
    if (stockA !== null && qtyA < stockA) {
      setQtyA(qtyA + 1);
    } else {
      toast.warning("Reached maximum available stock for Grade A.");
    }
  };

  const decrementQtyA = () => {
    if (qtyA > 0) {
      setQtyA(qtyA - 1);
    }
  };

  const incrementQtyB = () => {
    if (stockB !== null && qtyB < stockB) {
      setQtyB(qtyB + 1);
    } else {
      toast.warning("Reached maximum available stock for Grade B.");
    }
  };

  const decrementQtyB = () => {
    if (qtyB > 0) {
      setQtyB(qtyB - 1);
    }
  };

  const handlePurchase = async () => {
    if (processing) return;
    setProcessing(true);

    try {
      // Check stock availability
      if ((stockA !== null && qtyA > stockA) || (stockB !== null && qtyB > stockB)) {
        throw new Error("Not enough stock available.");
      }
      // Validate customer details
      if (!customerName || !customerEmail || !customerAddress || !customerCity || !customerPostalCode) {
        throw new Error("Please fill in all the required customer details.");
      }
      // Store order details for processing on the Success page
      const orderDetails = {
        customerEmail,
        customerName,
        customerAddress,
        customerCity,
        customerPostalCode,
        priceA,
        priceB,
        qtyA,
        qtyB,
        total
      };
      localStorage.setItem("orderDetails", JSON.stringify(orderDetails));

      // Prepare payload for Yoco checkout (amount in cents)
      const payload = {
        amount: Math.round(total * 100),
        currency: 'ZAR',
        successUrl: 'https://ndizachem.co.za/success',
        cancelUrl: 'https://ndizachem.co.za/cancel'
      };

      const response = await axios.post(
        'https://createyococheckout-bb4d2hrpta-uc.a.run.app',
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          withCredentials: false,
          timeout: 10000
        }
      );
      if (response.data?.redirectUrl) {
        toast.info("Redirecting to Yoco Checkout...");
        window.location.href = response.data.redirectUrl;
      } else {
        throw new Error("No checkout URL returned by server");
      }
    } catch (err) {
      let errorMessage = "Checkout error: ";
      if (err.response?.status === 405) {
        errorMessage += "Invalid request method";
      } else if (err.code === 'ECONNABORTED') {
        errorMessage += "Request timed out";
      } else if (err.message.includes('Network Error')) {
        errorMessage += "CORS error - Please ensure the server allows requests from this origin";
      } else if (err.response?.data?.message) {
        errorMessage += err.response.data.message;
      } else {
        errorMessage += err.message;
      }
      toast.error(errorMessage);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <ThemeProvider theme={farmTheme}>
      <CssBaseline />
      <Box
        sx={{
          minHeight: '100vh',
          backgroundColor: farmTheme.palette.background.default,
          py: 4,
        }}
      >
        <Container maxWidth="sm">
          <Paper
            sx={{
              p: 4,
              borderRadius: 2,
              boxShadow: 3,
              backgroundColor: '#ffffff',
              border: `2px solid ${farmTheme.palette.primary.main}`,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h3" sx={{ color: farmTheme.palette.primary.main, fontWeight: 'bold' }}>
                Checkout
              </Typography>
              <Button component={Link} to="/home" sx={{ color: farmTheme.palette.primary.main }}>
                &larr; Back
              </Button>
            </Box>

            <Box sx={{ mb: 4, textAlign: 'center', backgroundColor: '#FFF8E1', p: 2, borderRadius: 1 }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                Order Summary
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                  <Typography variant="subtitle1">Grade A Bale</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IconButton onClick={decrementQtyA} color="primary">
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ mx: 2 }}>{qtyA}</Typography>
                    <IconButton onClick={incrementQtyA} color="primary">
                      <AddIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    R{priceA.toFixed(2)} each
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'center' }}>
                  <Typography variant="subtitle1">Grade B Bale</Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IconButton onClick={decrementQtyB} color="primary">
                      <RemoveIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ mx: 2 }}>{qtyB}</Typography>
                    <IconButton onClick={incrementQtyB} color="primary">
                      <AddIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    R{priceB.toFixed(2)} each
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                Total: R{total.toFixed(2)}
              </Typography>
            </Box>

            <Box component="form" noValidate autoComplete="off" sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Email Address"
                type="email"
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
              <TextField
                fullWidth
                label="Full Name"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
              <TextField
                fullWidth
                label="Address"
                value={customerAddress}
                onChange={(e) => setCustomerAddress(e.target.value)}
                sx={{ mb: 2 }}
                required
              />
              <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="City"
                    value={customerCity}
                    onChange={(e) => setCustomerCity(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Postal Code"
                    value={customerPostalCode}
                    onChange={(e) => setCustomerPostalCode(e.target.value)}
                    required
                  />
                </Grid>
              </Grid>
            </Box>

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handlePurchase}
              disabled={processing || (qtyA === 0 && qtyB === 0)}
              sx={{ py: 1.5, fontWeight: 'bold' }}
            >
              {processing ? 'Processing Payment...' : 'Pay with Yoco'}
            </Button>
          </Paper>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Checkout;
