import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../firebase';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GoogleLogo from '../images/web_light_rd_na@1x.png';
import { 
  ThemeProvider, 
  createTheme, 
  CssBaseline, 
  Container, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Box,
  Divider
} from '@mui/material';

// Identical theme to other components
const farmTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#DAA520' },       // Goldenrod
    secondary: { main: '#8B4513' },     // SaddleBrown
    error: { main: '#d32f2f' },
    background: { default: '#F5DEB3', paper: '#ffffff' }, // Wheat background
    text: { primary: '#333333', secondary: '#555555' },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          transition: 'background-color 0.3s ease',
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: '#8B4513', // secondary color on hover
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: '#DAA520',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#DAA520',
            },
          },
        },
      },
    },
  },
});

const Signup = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSignup = async (e) => {
    e.preventDefault();
    
    // Add password confirmation
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      toast.success('Signup successful!');
      navigate('/home');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        toast.info('User already exists. Redirecting to login...');
        navigate('/login');
      } else {
        toast.error('Signup failed: ' + error.message);
      }
    }
  };

  const handleGoogleSignup = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      toast.success('Google sign-in successful!');
      navigate('/home');
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        toast.info('User already exists with a different credential. Try login.');
        navigate('/login');
      } else {
        toast.error('Google sign in failed: ' + error.message);
      }
    }
  };

  return (
    <ThemeProvider theme={farmTheme}>
      <CssBaseline />
      <Box 
        sx={{ 
          minHeight: '100vh', 
          backgroundColor: farmTheme.palette.background.default,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Header />
        
        <Container 
          maxWidth="xs" 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center', 
            flex: 1,
            py: 4 
          }}
        >
          <Paper 
            elevation={3} 
            sx={{ 
              p: 4, 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              borderRadius: 2,
              backgroundColor: 'white'
            }}
          >
            <Typography 
              component="h1" 
              variant="h4" 
              sx={{ 
                mb: 3, 
                color: farmTheme.palette.primary.main,
                fontWeight: 'bold'
              }}
            >
              Sign Up
            </Typography>
            
            <form onSubmit={handleSignup} style={{ width: '100%' }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                helperText="Password must be at least 6 characters long"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirm-password"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                error={password !== confirmPassword && confirmPassword !== ''}
                helperText={
                  password !== confirmPassword && confirmPassword !== ''
                    ? 'Passwords do not match'
                    : ''
                }
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ 
                  mt: 3, 
                  mb: 2,
                  py: 1.5,
                  fontWeight: 'bold'
                }}
              >
                Sign Up
              </Button>
              
              <Divider sx={{ my: 2 }}>
                <Typography variant="body2" color="text.secondary">
                  OR
                </Typography>
              </Divider>
              
              <Button
                fullWidth
                variant="outlined"
                onClick={handleGoogleSignup}
                sx={{ 
                  mt: 1, 
                  mb: 2,
                  py: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2
                }}
              >
                <img 
                  src={GoogleLogo} 
                  alt="Google logo" 
                  style={{ height: 24, width: 24 }} 
                />
                Sign up with Google
              </Button>
            </form>
            
            <Typography 
              variant="body2" 
              sx={{ 
                mt: 2, 
                color: farmTheme.palette.text.secondary 
              }}
            >
              Already have an account?{' '}
              <Link 
                to="/login" 
                style={{ 
                  color: farmTheme.palette.primary.main,
                  textDecoration: 'none',
                  fontWeight: 'bold'
                }}
              >
                Login
              </Link>
            </Typography>
          </Paper>
        </Container>
        
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default Signup;