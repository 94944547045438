// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { supabase } from '../supabase';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Tabs,
  Tab,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import { ChevronDown, Mail, CheckCircle } from 'lucide-react';

const farmTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#DAA520' },
    secondary: { main: '#8B4513' },
    error: { main: '#d32f2f' },
    background: { default: '#F5DEB3', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#555555' },
  },
});

const formatDate = (postgresTimestamp) => {
  if (!postgresTimestamp) return 'N/A';
  try {
    const date = new Date(postgresTimestamp);
    return new Intl.DateTimeFormat('en-ZA', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'Africa/Johannesburg',
    }).format(date);
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Invalid Date';
  }
};

const GroupedOrders = ({ orders, selectedOrderIds, onOrderSelect, onEmailSent }) => {
  const groupedOrders = orders.reduce((acc, order) => {
    if (!acc[order.customer_email]) {
      acc[order.customer_email] = [];
    }
    acc[order.customer_email].push(order);
    return acc;
  }, {});

  Object.values(groupedOrders).forEach((orderGroup) => {
    orderGroup.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  });

  return (
    <div className="space-y-4">
      {Object.entries(groupedOrders).map(([email, customerOrders]) => (
        <Accordion key={email}>
          <AccordionSummary
            expandIcon={<ChevronDown className="w-5 h-5" />}
            className="hover:bg-gray-50"
          >
            <div className="flex items-center justify-between w-full pr-4">
              <Typography variant="h6">{email}</Typography>
              <Typography variant="body2" color="text.secondary">
                {customerOrders.length} order
                {customerOrders.length !== 1 ? 's' : ''} - Latest: {formatDate(customerOrders[0].created_at)}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Select</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Order Number</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Paid</TableCell>
                    <TableCell>Qty A</TableCell>
                    <TableCell>Qty B</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Email Sent</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customerOrders.map((order) => (
                    <TableRow
                      key={order.id}
                      sx={{
                        backgroundColor: order.email_sent ? 'inherit' : '#fff3e0',
                        '&:hover': {
                          backgroundColor: order.email_sent ? '#f5f5f5' : '#ffe0b2',
                        },
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedOrderIds.includes(order.id)}
                          onChange={() => onOrderSelect(order.id)}
                        />
                      </TableCell>
                      <TableCell>{formatDate(order.created_at)}</TableCell>
                      <TableCell>{order.orderNum}</TableCell>
                      <TableCell>{order.customer_name}</TableCell>
                      <TableCell>{order.paid ? "Yes" : "No"}</TableCell>
                      <TableCell>{order.qtyA}</TableCell>
                      <TableCell>{order.qtyB}</TableCell>
                      <TableCell>R{order.total}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => onEmailSent(order.id)}
                          color={order.email_sent ? "primary" : "default"}
                          size="small"
                          disabled={order.email_sent}
                        >
                          {order.email_sent ? (
                            <CheckCircle className="w-5 h-5" />
                          ) : (
                            <Mail className="w-5 h-5" />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

const StockTab = ({ onRefresh }) => {
  const [stock, setStock] = useState([]);
  const [availableInputs, setAvailableInputs] = useState({});
  const [reservedInputs, setReservedInputs] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchStock();
  }, []);

  const fetchStock = async () => {
    try {
      const { data, error } = await supabase
        .from('wheatStock')
        .select('*')
        .in('id', [1, 2]);
      if (error) throw error;
      setStock(data);
      const avail = {};
      const resvd = {};
      data.forEach((item) => {
        avail[item.id] = item.available;
        resvd[item.id] = item.reserved;
      });
      setAvailableInputs(avail);
      setReservedInputs(resvd);
    } catch (err) {
      setMessage("Error fetching stock: " + err.message);
    }
  };

  const handleStockUpdate = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      for (const id of [1, 2]) {
        const newAvailable = Number(availableInputs[id]);
        const newReserved = Number(reservedInputs[id]);
        const newTotal = newAvailable + newReserved;
        const { error } = await supabase
          .from('wheatStock')
          .update({
            available: newAvailable,
            reserved: newReserved,
            total: newTotal,
          })
          .eq('id', id);
        if (error) throw error;
      }
      await fetchStock();
      toast.success("Stock updated successfully!");
      onRefresh();
    } catch (err) {
      toast.error("Error updating stock: " + err.message);
    }
  };

  return (
    <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3, mt: 4 }}>
      <Typography variant="h3" sx={{ mb: 4, color: farmTheme.palette.primary.main, fontWeight: 'bold' }}>
        Admin Dashboard - Stock
      </Typography>
      <Typography variant="body1" sx={{ mb: 3, color: farmTheme.palette.text.secondary }}>
        Override available and reserved bales for each grade. Total is automatically calculated.
      </Typography>
      <form onSubmit={handleStockUpdate}>
        <Grid container spacing={3}>
          {[1, 2].map((id) => (
            <React.Fragment key={id}>
              <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: farmTheme.palette.primary.main }}>
                  Grade {id === 1 ? 'A' : 'B'} Bale (ID: {id})
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Available"
                  variant="outlined"
                  value={availableInputs[id] || ''}
                  onChange={(e) =>
                    setAvailableInputs({ ...availableInputs, [id]: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="number"
                  label="Reserved"
                  variant="outlined"
                  value={reservedInputs[id] || ''}
                  onChange={(e) =>
                    setReservedInputs({ ...reservedInputs, [id]: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  label="Total"
                  variant="outlined"
                  value={
                    Number(availableInputs[id] || 0) +
                    Number(reservedInputs[id] || 0)
                  }
                  InputProps={{ readOnly: true }}
                  sx={{ backgroundColor: '#F0F0F0' }}
                />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary" sx={{ py: 1.5, fontWeight: 'bold' }}>
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </form>
      {message && (
        <Typography sx={{ mt: 3, textAlign: 'center', color: farmTheme.palette.success.main }}>
          {message}
        </Typography>
      )}
    </Paper>
  );
};

const OrdersTab = ({ onRefresh }) => {
  const [orders, setOrders] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const { data, error } = await supabase
        .from('orders')
        .select('id, orderNum, customer_email, customer_name, paid, qtyA, qtyB, total, created_at, email_sent, priceA, priceB')
        .eq('fulfilled', false)
        .order('created_at', { ascending: false });
      if (error) throw error;
      setOrders(data);
    } catch (err) {
      toast.error("Error fetching orders: " + err.message);
    }
  };

  const handleOrderCheckboxChange = (orderId) => {
    setSelectedOrderIds((prev) =>
      prev.includes(orderId)
        ? prev.filter((id) => id !== orderId)
        : [...prev, orderId]
    );
  };

  const handleEmailSent = async (orderId) => {
    const order = orders.find((order) => order.id === orderId);
    if (!order) {
      toast.error("Order not found.");
      return;
    }

    const maxAttempts = 3;
    let attempt = 0;
    let emailSent = false;

    while (attempt < maxAttempts && !emailSent) {
      try {
        await axios.post(
          'https://us-central1-ndizachem.cloudfunctions.net/sendEmailConfirmation',
          {
            customerName: order.customer_name,
            customerEmail: order.customer_email,
            qtyA: order.qtyA,
            qtyB: order.qtyB,
            priceA: order.priceA,
            priceB: order.priceB,
            total: order.total,
          },
          { headers: { 'Content-Type': 'application/json' } }
        );
        emailSent = true;
        toast.success("Email confirmation sent successfully!");
        const { error } = await supabase
          .from('orders')
          .update({ email_sent: true })
          .eq('id', orderId);
        if (error) throw error;
        setOrders((prevOrders) =>
          prevOrders.map((o) =>
            o.id === orderId ? { ...o, email_sent: true } : o
          )
        );
      } catch (err) {
        attempt++;
        if (attempt >= maxAttempts) {
          toast.error("Failed to send email confirmation after multiple attempts.");
        } else {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
      }
    }
  };

  const handleFulfillOrders = async () => {
    if (selectedOrderIds.length === 0) {
      toast.info("No orders selected.");
      return;
    }

    try {
      const selectedOrders = orders.filter((order) =>
        selectedOrderIds.includes(order.id)
      );
      const totalQtyAToFulfill = selectedOrders.reduce(
        (acc, order) => acc + order.qtyA,
        0
      );
      const totalQtyBToFulfill = selectedOrders.reduce(
        (acc, order) => acc + order.qtyB,
        0
      );

      const { error: orderUpdateError } = await supabase
        .from('orders')
        .update({ fulfilled: true })
        .in('id', selectedOrderIds);
      if (orderUpdateError) throw orderUpdateError;

      // Update stock for each grade.
      for (const id of [1, 2]) {
        const { data: stockData, error: stockError } = await supabase
          .from('wheatStock')
          .select('*')
          .eq('id', id)
          .single();
        if (stockError) throw stockError;
        const qtyToDeduct = id === 1 ? totalQtyAToFulfill : totalQtyBToFulfill;
        const newReserved = (stockData.reserved || 0) - qtyToDeduct;
        const newAvailable = stockData.available; // assuming available remains unchanged here
        const newTotal = newAvailable + newReserved;
        const { error: stockUpdateError } = await supabase
          .from('wheatStock')
          .update({ reserved: newReserved, total: newTotal })
          .eq('id', id);
        if (stockUpdateError) throw stockUpdateError;
      }

      toast.success("Selected orders marked as fulfilled.");
      fetchOrders();
      onRefresh();
      setSelectedOrderIds([]);
    } catch (err) {
      toast.error("Error fulfilling orders: " + err.message);
    }
  };

  return (
    <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3, mt: 4 }}>
      <Typography variant="h3" sx={{ mb: 4, color: farmTheme.palette.primary.main, fontWeight: 'bold' }}>
        Unfulfilled Orders
      </Typography>
      {orders.length === 0 ? (
        <Typography variant="body1" sx={{ color: farmTheme.palette.text.secondary }}>
          No unfulfilled orders.
        </Typography>
      ) : (
        <>
          <GroupedOrders
            orders={orders}
            selectedOrderIds={selectedOrderIds}
            onOrderSelect={handleOrderCheckboxChange}
            onEmailSent={handleEmailSent}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFulfillOrders}
              sx={{ py: 1.5, fontWeight: 'bold' }}
            >
              Save (Mark as Fulfilled)
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};

const PriceTab = ({ onRefresh }) => {
  const [prices, setPrices] = useState({});
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchPrices();
  }, []);

  const fetchPrices = async () => {
    try {
      const { data, error } = await supabase
        .from('wheatStock')
        .select('*')
        .in('id', [1, 2]);
      if (error) throw error;
      const newPrices = {};
      data.forEach(product => {
        newPrices[product.id] = product.price;
      });
      setPrices(newPrices);
    } catch (err) {
      setMessage("Error fetching prices: " + err.message);
    }
  };

  const handlePriceChange = (id, newPrice) => {
    setPrices({ ...prices, [id]: newPrice });
  };

  const updatePrice = async (id) => {
    try {
      const { error } = await supabase
        .from('wheatStock')
        .update({ price: prices[id] })
        .eq('id', id);
      if (error) throw error;
      toast.success(`Price for Grade ${id === 1 ? 'A' : 'B'} Bale updated!`);
      fetchPrices();
      onRefresh();
    } catch (err) {
      toast.error("Error updating price: " + err.message);
    }
  };

  return (
    <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3, mt: 4 }}>
      <Typography variant="h3" sx={{ mb: 4, color: farmTheme.palette.primary.main, fontWeight: 'bold' }}>
        Update Product Prices
      </Typography>
      <Grid container spacing={3}>
        {[1, 2].map(id => (
          <React.Fragment key={id}>
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: farmTheme.palette.primary.main }}>
                Grade {id === 1 ? 'A' : 'B'} Bale
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                type="number"
                label="Price"
                variant="outlined"
                value={prices[id] || ''}
                onChange={(e) => handlePriceChange(id, e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <Button onClick={() => updatePrice(id)} variant="contained" color="primary" fullWidth>
                Update
              </Button>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      {message && (
        <Typography sx={{ mt: 3, textAlign: 'center', color: farmTheme.palette.error.main }}>
          {message}
        </Typography>
      )}
    </Paper>
  );
};

const AdminDashboard = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [user, setUser] = useState(null);

  // Check authentication and admin status.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe;
  }, []);

  // If user is not an admin, block the page.
  if (!user || !(
      user.email === 'donatelloc@slc.za.com' ||
      user.email === 'quentinv@slc.za.com' ||
      user.email === 'sales@ndizachem.co.za' ||
      user.email === 'novussec@gmail.com'
    )) {
    return (
      <ThemeProvider theme={farmTheme}>
        <CssBaseline />
        <Header />
        <Container maxWidth="sm" sx={{ mt: 8, textAlign: 'center' }}>
          <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h4" sx={{ color: farmTheme.palette.error.main, fontWeight: 'bold', mb: 2 }}>
              Not Authorized
            </Typography>
            <Typography variant="body1" sx={{ color: farmTheme.palette.text.secondary }}>
              You do not have access to this page.
            </Typography>
          </Paper>
        </Container>
        <Footer />
      </ThemeProvider>
    );
  }

  const refresh = () => {}; // placeholder for refreshing data if needed

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <ThemeProvider theme={farmTheme}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', backgroundColor: farmTheme.palette.background.default }}>
        <Header />
        <Container maxWidth="md" sx={{ mt: 8, mb: 4 }}>
          <Tabs value={tabIndex} onChange={handleTabChange} centered>
            <Tab label="Stock" />
            <Tab label="Orders" />
            <Tab label="Price" />
          </Tabs>
          {tabIndex === 0 && <StockTab onRefresh={refresh} />}
          {tabIndex === 1 && <OrdersTab onRefresh={refresh} />}
          {tabIndex === 2 && <PriceTab onRefresh={refresh} />}
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default AdminDashboard;
