// src/pages/Success.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabase';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
  Paper,
  Typography,
  Button,
} from '@mui/material';
import axios from "axios";

const farmTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#DAA520' },
    secondary: { main: '#8B4513' },
    error: { main: '#d32f2f' },
    background: { default: '#F5DEB3', paper: '#ffffff' },
    text: { primary: '#333333', secondary: '#555555' },
  },
});

const Success = () => {
  const [loading, setLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState({
    inserted: false,
    emailSent: false,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    const processOrder = async () => {
      const orderDetails = JSON.parse(localStorage.getItem('orderDetails'));
      if (!orderDetails) {
        setError('No order details found.');
        setLoading(false);
        return;
      }

      // Update stock for Grade A and Grade B
      for (const id of [1, 2]) {
        const { data: stockData, error: stockError } = await supabase
          .from('wheatStock')
          .select('*')
          .eq('id', id)
          .single();
        if (stockError) {
          setError(stockError.message);
          setLoading(false);
          return;
        }
        const currentAvailable = stockData.available;
        const currentReserved = stockData.reserved || 0;
        const qtyOrdered = id === 1 ? Number(orderDetails.qtyA) : Number(orderDetails.qtyB);
        const newAvailable = currentAvailable - qtyOrdered;
        const newReserved = currentReserved + qtyOrdered;
        const newTotal = newAvailable + newReserved;
        const { error: updateStockError } = await supabase
          .from('wheatStock')
          .update({
            available: newAvailable,
            reserved: newReserved,
            total: newTotal,
          })
          .eq('id', id);
        if (updateStockError) {
          setError(updateStockError.message);
          setLoading(false);
          return;
        }
      }

      // Generate order number.
      const orderNum = 'OrderNumber_' + Date.now();
      
      // Attempt to send an email confirmation via Cloud Function.
      let emailSent = false;
      try {
        const emailResponse = await axios.post(
          'https://us-central1-ndizachem.cloudfunctions.net/sendEmailConfirmation',
          {
            customerName: orderDetails.customerName,
            customerEmail: orderDetails.customerEmail,
            qtyA: orderDetails.qtyA,
            qtyB: orderDetails.qtyB,
            priceA: orderDetails.priceA,
            priceB: orderDetails.priceB,
            total: orderDetails.total,
          },
          { headers: { 'Content-Type': 'application/json' } }
        );
        console.log('Email response:', emailResponse.data);
        emailSent = true;
      } catch (emailError) {
        console.error('Error sending email confirmation:', emailError);
      }
      
      // Insert the order record into the orders table, including the order number.
      const { error: orderError } = await supabase.from('orders').insert([
        {
          orderNum,
          customer_name: orderDetails.customerName,
          customer_email: orderDetails.customerEmail,
          customer_address: orderDetails.customerAddress,
          customer_city: orderDetails.customerCity,
          customer_postal_code: orderDetails.customerPostalCode,
          qtyA: orderDetails.qtyA,
          qtyB: orderDetails.qtyB,
          priceA: orderDetails.priceA,
          priceB: orderDetails.priceB,
          total: orderDetails.total,
          paid: true,
          email_sent: emailSent,
          fulfilled: false,
        },
      ]);

      if (orderError) {
        setError(orderError.message);
        setLoading(false);
        return;
      }

      setOrderStatus({
        inserted: true,
        emailSent: emailSent,
      });
      setLoading(false);

      // Clear the cart and order details from localStorage.
      localStorage.removeItem('cart');
      localStorage.removeItem('orderDetails');
    };

    processOrder();
  }, []);

  return (
    <ThemeProvider theme={farmTheme}>
      <CssBaseline />
      <Container maxWidth="sm" sx={{ py: 4 }}>
        <Paper sx={{ p: 4, borderRadius: 2, boxShadow: 3, textAlign: 'center' }}>
          <Typography
            variant="h3"
            sx={{ fontWeight: 'bold', color: farmTheme.palette.primary.main, mb: 3 }}
          >
            Payment Successful
          </Typography>
          {loading ? (
            <Typography variant="body1" sx={{ color: farmTheme.palette.text.secondary }}>
              Updating your order details...
            </Typography>
          ) : error ? (
            <Typography variant="body1" sx={{ color: farmTheme.palette.error.main }}>
              Error: {error}
            </Typography>
          ) : orderStatus.inserted ? (
            <>
              <Typography variant="body1" sx={{ color: farmTheme.palette.text.secondary, mb: 3 }}>
                Your payment was successful and your order has been recorded.
                {orderStatus.emailSent 
                  ? ' A confirmation email has been sent to your email address including your order number.'
                  : " We're experiencing issues with our email system. Our team will contact you shortly to confirm your order."}
              </Typography>
              <Button component="a" href="/home" variant="contained" color="primary">
                Return Home
              </Button>
            </>
          ) : (
            <Typography variant="body1" sx={{ color: farmTheme.palette.text.secondary }}>
              Unexpected state.
            </Typography>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default Success;
